import React, { useContext } from 'react';
import {
  arrayOf, bool, func, number, shape, string, any
} from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useDeleteLineItem } from '@thd-olt-component-react/cart-common-data';
import { AddToCart } from '@thd-olt-component-react/add-to-cart';
import { getCartOptions, getCartReqParams } from '../../BuyboxHelper';
import { useBogoInfo } from '../hooks/useBogoInfo';
import '../buybox.scss';

const BuyboxAddToCart = ({
  alterBrowserHistory,
  disableATC,
  fulfillment,
  fulfillmentMethod,
  isAssembleItemAtStore,
  itemId,
  configurableItems,
  onClickPassThrough,
  onATCClick,
  configuratorCart,
  productAddOns = {},
  quantity,
  originalConfigId,
  quantityLimitExceed,
  storeId,
  storeZip,
  zipCode,
  showFavoriteAndQuote,
  isSpecialOrder,
  customATC,
}) => {
  const { channel } = useContext(ExperienceContext);
  const [deleteItems] = useDeleteLineItem();
  const bogoInfo = useBogoInfo();
  const buttonText = bogoInfo?.bogoSelectedProduct?.itemId ? 'Add Both to Cart' : 'Add to Cart';
  const isSubscribeAndSaveOpted = !!productAddOns?.subscriptions?.frequency;

  const cartReqParams = configuratorCart
    || getCartReqParams({
      bogoInfo,
      fulfillment,
      fulfillmentMethod,
      isAssembleItemAtStore,
      itemId,
      configurableItems,
      quantity,
      originalConfigId,
      productAddOns,
      storeId,
      storeZip,
      zipCode
    });

  const cartOptions = getCartOptions({
    configurableItems,
    alterBrowserHistory,
    bogoInfo,
    channel,
    fulfillmentMethod,
    storeId,
    storeZip,
    zipCode,
    isConfigurableProduct: !!configuratorCart
  });

  let handleOnClickPassthrough = onClickPassThrough;
  if (customATC && isSpecialOrder) {
    handleOnClickPassthrough = async () => {
      const lineItemGuid = await customATC(cartOptions);
      if (lineItemGuid) {
        deleteItems([lineItemGuid]);
      }
    };
  }

  return (
    <div className={!showFavoriteAndQuote ? 'buybox__atc' : 'buybox__cart-and-favorites'}>
      <AddToCart
        cartOptions={cartOptions}
        cartReqParams={cartReqParams}
        disabled={disableATC || quantityLimitExceed}
        onClickPassThrough={handleOnClickPassthrough}
        onClick={onATCClick}
        variant="primary"
        showIcon
      >
        {isSubscribeAndSaveOpted ? 'Subscribe' : buttonText }
      </AddToCart>
    </div>
  );
};

BuyboxAddToCart.propTypes = {
  alterBrowserHistory: bool,
  disableATC: bool,
  fulfillment: shape({
    type: string,
    locations: arrayOf(shape({
      isAnchor: bool,
      inventory: shape({
        quantity: number,
        isInStock: bool,
        isLimitedQuantity: bool,
        isBackordered: bool
      })
    }))
  }),
  fulfillmentMethod: string,
  isAssembleItemAtStore: bool,
  itemId: string.isRequired,
  configurableItems: arrayOf(
    shape({
      itemId: string,
      isAnchor: bool,
    })
  ),
  onClickPassThrough: func,
  onATCClick: func,
  // eslint-disable-next-line react/forbid-prop-types
  configuratorCart: arrayOf(any),
  productAddOns: shape({
    attachedLabor: arrayOf(shape({
      attachedLaborSku: string
    })),
    warrantyItem: shape({
      itemId: string
    })
  }),
  quantity: number,
  quantityLimitExceed: bool,
  storeId: string,
  storeZip: string,
  zipCode: string,
  showFavoriteAndQuote: bool,
  isSpecialOrder: bool,
  customATC: func,
  originalConfigId: string
};

BuyboxAddToCart.defaultProps = {
  alterBrowserHistory: false,
  configurableItems: null,
  onClickPassThrough: null,
  disableATC: false,
  fulfillment: null,
  fulfillmentMethod: '',
  isAssembleItemAtStore: null,
  onATCClick: () => {},
  configuratorCart: null,
  productAddOns: null,
  quantity: 1,
  quantityLimitExceed: false,
  storeId: null,
  storeZip: null,
  zipCode: null,
  showFavoriteAndQuote: false,
  isSpecialOrder: false,
  customATC: () => {},
  originalConfigId: null
};

export { BuyboxAddToCart };
