import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from '@thd-olt-component-react/button';
import '../../styles/results-applied.scss';
import { filterCatStyleCheckAndReturnUrl } from '../../product-results-helpers';

const AppliedRefinement = ({
  index,
  dimensionName,
  deselectUrl,
  label,
  onChange,
  refinementKey,
  onDisplay,
}) => {

  const handleOnChange = () => {
    if (onChange) {

      let deselectUrlRevised = filterCatStyleCheckAndReturnUrl(deselectUrl);

      onChange({
        refinement: {
          label,
          url: deselectUrlRevised,
          refinementKey
        },
        dimension: {
          label: dimensionName
        },
        onDisplay
      });
    }
  };

  const buttonClasses = classNames('results-applied__bubble');

  return (
    <Button
      data-remove-url={deselectUrl}
      className={buttonClasses}
      filter
      key={`applied-dimension-${index + refinementKey}`}
      onClick={handleOnChange}
      value={dimensionName}
    >
      {`${dimensionName}: ${label}`}
    </Button>
  );
};

AppliedRefinement.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  dimensionName: PropTypes.string,
  deselectUrl: PropTypes.string,
  label: PropTypes.string,
  refinementKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDisplay: PropTypes.string,
};

AppliedRefinement.defaultProps = {
  index: null,
  dimensionName: null,
  deselectUrl: null,
  label: null,
  refinementKey: null,
  onDisplay: 'false',
};

AppliedRefinement.displayName = 'AppliedRefinement';

export { AppliedRefinement };
